<script setup lang="ts">
import { format } from 'date-fns'
import { useAdminWorkspaceManger } from '~/stores/adminWorkspaces'
import { useAppConfiguration } from '~/stores/appConfiguration'
import { useWorkspace } from '~/stores/workspace'

const navOpen = ref(false)
const isMounted = useMounted()

const workspace = useWorkspace()
const admin = useAdminWorkspaceManger()
const appConfig = useAppConfiguration()
const config = useRuntimeConfig()

const infoOpen = ref(false)
const menuOpen = ref(false)

const version = computed(() => `v${config.public.APP_VERSION}`)

const { timestamp } = useClock()
const date = computed(() => new Date(timestamp.value))

const openInfoPanel = () => {
  infoOpen.value = true
}

onMounted(() => {
  admin.getAll()
})
</script>

<template>
  <div>
    <ClientOnly>
      <AppNavDrawer
        v-if="isMounted && workspace.isRialticAdmin"
        v-model="navOpen"
        :version="version"
        mini
      >
        <template #nav>
          <NavMenu admin />
        </template>
        <template #default="{ isMini }">
          <div class="flex-1">
            <div class="relative flex">
              <aside
                class="w-50 bg-primary-700 border-primary fixed z-10 h-screen transform border-r pt-10 transition lg:transform-none lg:pt-0"
                :class="{
                  'max-w-18': !isMini,
                  'translate-x-0': menuOpen,
                  '-translate-x-50': !menuOpen,
                }"
                aria-label="admin nav menu"
              >
                <div class="flex h-full flex-col justify-between py-4">
                  <div>
                    <div id="layoutAdminNavMenu" />
                    <ul
                      class="flex w-full flex-col items-start justify-start space-y-4 px-3 text-neutral-300"
                    >
                      <li class="w-full">
                        <AppNavDrawerItem to="/admin">
                          <template #icon>
                            <div class="i-ph-squares-four h-full w-6" />
                          </template>
                          Workspaces
                        </AppNavDrawerItem>
                      </li>
                      <li class="w-full">
                        <AppNavDrawerItem to="/admin/users">
                          <template #icon>
                            <div class="i-ph-user-list h-full w-6" />
                          </template>
                          All users
                        </AppNavDrawerItem>
                      </li>
                      <li class="w-full">
                        <AppNavDrawerItem to="/admin/api-admin">
                          <template #icon>
                            <div class="i-ph-circles-three h-full w-6" />
                          </template>
                          API Admin
                        </AppNavDrawerItem>
                      </li>
                      <li class="w-full">
                        <AppNavDrawerItem to="/admin/app-config">
                          <template #icon>
                            <div class="i-ph-gear-six h-full w-6" />
                          </template>
                          App config
                        </AppNavDrawerItem>
                      </li>
                    </ul>
                  </div>
                  <footer v-if="isMini" class="px-4 text-sm text-neutral-300">
                    {{ version }}
                  </footer>
                </div>
              </aside>

              <!-- Page start -->
              <div
                class="relative flex w-full flex-1 flex-col"
                :class="{
                  'lg:pl-50': isMini,
                  'lg:pl-18': !isMini,
                }"
              >
                <header
                  class="bg-surface-bg sticky top-0 z-10 flex h-10 items-center justify-between px-4 py-2"
                >
                  <div class="flex items-center space-x-2">
                    <button
                      class="btn btn-icon border-none hover:bg-neutral-200 lg:hidden"
                      @click="menuOpen = !menuOpen"
                    >
                      <div class="i-ph-list-bold h-5 w-5" />
                    </button>
                    <div id="layoutAdminAppBar" />
                  </div>
                  <div class="flex items-center space-x-2">
                    <div
                      v-if="appConfig.maintenance"
                      class="bg-warning border-warning-dark rounded px-2 py-1 text-xs"
                    >
                      Maintenance mode
                    </div>
                    <button
                      class="btn btn-icon border-none"
                      aria-label="open info panel"
                      type="button"
                      @click="openInfoPanel()"
                    >
                      <div class="i-ph-info-bold" />
                    </button>
                    <div>
                      <time :datetime="date.toLocaleTimeString()">
                        {{ format(date, 'HH:mm') }}
                      </time>
                    </div>
                  </div>
                </header>
                <slot />
              </div>
            </div>
            <AdminInfo
              v-if="infoOpen"
              :info="admin.platformHealth"
              @close="infoOpen = false"
            />
          </div>
        </template>
      </AppNavDrawer>
      <div v-else-if="workspace.isRialticAdmin">Loading</div>
      <div v-else>No access</div>
    </ClientOnly>
  </div>
</template>
