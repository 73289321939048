<script setup lang="ts">
import { useAdminWorkspaceManger } from '~/stores/adminWorkspaces'

const admin = useAdminWorkspaceManger()

const props = defineProps<{ info: any }>()
defineEmits(['close'])

const apiInfo = computed(() => {
  const { config, host } = { ...props.info?.api }

  const apiInfoItems = [
    {
      label: 'Worker version',
      value: `v${props.info?.workerVersion}`,
    },
    {
      label: 'Console API host',
      value: host,
    },
    {
      label: 'Console DB connection',
      value: config?.DB?.DB_CONNECTION_URL,
    },
    {
      label: 'Slack Env',
      value: config?.SLACK?.SLACK_MSG_ENV,
    },
    {
      label: 'Slack Channel ID',
      value: config?.SLACK?.SLACK_APP_CHANNEL_ID,
    },
    {
      label: 'Logger API key',
      value: config?.LOGGER?.LOGGER_API_KEY,
    },
    {
      label: 'Utilities API key',
      value: config?.UTILITIES.UTILITIES_API_KEY,
    },
    {
      label: 'Replication Enabled',
      value: config?.REPLICATION?.REPLICATION_ENABLED,
    },
    {
      label: 'Replication Source URL',
      value: config?.REPLICATION?.REPLICATION_SOURCE_URL,
    },
    {
      label: 'Replication Source Username',
      value: config?.REPLICATION?.REPLICATION_SOURCE_USERNAME,
    },
    {
      label: 'Replication Source Password',
      value: config?.REPLICATION?.REPLICATION_SOURCE_PASSWORD,
    },
  ]

  return apiInfoItems
})

const healthInfo = computed(() => {
  const { consoleDB, cognito } = { ...props.info?.api?.healthChecks }

  return [
    {
      label: 'Cognito',
      available: /^Passed/.test(cognito),
      message: cognito,
    },
    {
      label: 'Console DB',
      available: /^Passed/.test(consoleDB),
      message: consoleDB,
    },
  ]
})

const dbHealthInfo = computed(() => {
  const { database } = { ...props.info?.api?.healthChecks }
  return {
    label: 'Database Details',
    results: database,
  }
})

const dataIntegrityInfo = computed(() => {
  const { checks } = { ...props.info?.api?.healthChecks?.data_integrity }
  const { issues } = { ...props.info?.api?.healthChecks?.policy_integrity }
  const policyErrors = issues?.filter((issue) => issue.level === 'error') ?? []
  let passed = true
  if (
    policyErrors?.length ||
    (checks?.length && checks.some((check) => check.items.length))
  )
    passed = false
  return {
    label: 'Data Integrity',
    checks: checks?.length ? checks : [],
    policyErrors,
    passed,
  }
})

const muxHealthInfo = computed(() => {
  const { hiro } = { ...props.info?.api?.healthChecks }
  const hasPassed = hiro?.workspaces?.some(
    ({ result }) => /^Passed/.test(result) | /^ valid/.test(result),
  )
  const hasFailed = hiro?.workspaces?.some(({ result }) =>
    /^Failed/.test(result),
  )
  return {
    available: hasPassed && !hasFailed,
    warning: hasPassed && hasFailed,
    results: hiro?.workspaces ?? [],
  }
})

const userInfo = computed(() => {
  const user = props.info?.user || {}
  return [
    {
      label: 'User email',
      value: user.authUsername,
    },
    {
      label: 'User cognito id',
      value: user.authUid,
    },
    {
      label: 'City',
      value: user.location?.city,
    },
    {
      label: 'Region',
      value: user.location?.region,
    },
    {
      label: 'Country',
      value: user.location?.country,
    },
    {
      label: 'Provider',
      value: user.location?.asOrganization,
    },
    {
      label: 'Timezone',
      value: user.location?.timezone,
    },
  ]
})

const { pending, refresh } = useAsyncData('admin-platform-health', () =>
  admin.getPlatformHealth(),
)
</script>

<template>
  <AppDetailsDrawer>
    <template #header>
      <header class="flex items-center justify-between border-b px-4 py-2">
        <div class="flex items-center space-x-2">
          <h2 class="text-neutral-800">Information</h2>

          <UiButton :loading="pending" variant="text" @click="refresh">
            <span class="inline-flex items-center">
              Refresh
              <i class="i-ph-arrow-clockwise ml-1 inline-block h-4 w-4" />
            </span>
          </UiButton>
        </div>
        <UiBtnClose @click="$emit('close')" />
      </header>
    </template>
    <div class="p-4">
      <section class="mb-4">
        <h2 class="h2 text-neutral-900">Health checks</h2>
        <ul>
          <li
            v-for="{ available, label, message } in healthInfo"
            class="flex items-center space-x-2 py-2"
          >
            <div
              class="h-6 w-6"
              :class="{
                'i-heroicons-check-circle-20-solid text-success': available,
                'i-heroicons-exclamation-circle-20-solid text-error':
                  !available,
              }"
            />
            <div class="h2 text-neutral-800">{{ label }}</div>
            <div v-if="message">-</div>
            <div class="subtitle-2 py-1 text-neutral-900">{{ message }}</div>
          </li>
          <li
            v-if="dbHealthInfo.results"
            class="mb-4 overflow-hidden rounded-md border border-neutral-300"
          >
            <UiListItemExpand>
              <div class="flex">
                <div
                  class="mr-3 h-6 w-6"
                  :class="[
                    {
                      'i-heroicons-check-circle-20-solid text-success':
                        dbHealthInfo.results.dataCheck === 'Passed.',
                    },
                    {
                      'i-heroicons-exclamation-circle-20-solid text-error':
                        dbHealthInfo.results.dataCheck !== 'Passed.',
                    },
                  ]"
                />
                {{ dbHealthInfo.label }}
              </div>
              <template #content>
                <div class="subtitle-2 py-1 text-neutral-900">
                  <ul class="px-5">
                    <li class="py-2">
                      Data Check: {{ dbHealthInfo.results.dataCheck }}
                    </li>
                    <li class="py-2">
                      Counts:
                      <ul class="px-5">
                        <li class="h-2">
                          Workspaces:
                          {{ dbHealthInfo.results.counts.countWorkspaces }}
                        </li>
                        <li class="h-2 py-2">
                          Deleted Workspaces:
                          {{
                            dbHealthInfo.results.counts.countDeletedWorkspaces
                          }}
                        </li>
                        <li class="h-2 py-2">
                          Connectors:
                          {{ dbHealthInfo.results.counts.countConnectors }}
                        </li>
                        <li class="h-2 py-2">
                          Policies:
                          {{ dbHealthInfo.results.counts.countPolicies }}
                        </li>
                      </ul>
                    </li>
                    <li class="py-2">
                      DB Health: {{ dbHealthInfo.results.databaseHealth }}
                    </li>
                    <li>
                      Connections:
                      {{
                        dbHealthInfo.results.databaseHealthDetails.connections
                      }}
                    </li>
                    <li class="h-2 py-2">
                      Size:
                      {{
                        dbHealthInfo.results.databaseHealthDetails.size[0]
                          .pg_size_pretty
                      }}
                    </li>
                    <li class="mt-3 py-2">
                      Table Bloat:
                      <ul class="px-2">
                        <li
                          v-for="(table, index) in dbHealthInfo.results
                            .databaseHealthDetails.tableBloat"
                          :key="index"
                          class="h-2"
                          :class="{ 'py-2': index > 0 }"
                        >
                          Table
                          <span class="font-bold">{{ table.relname }}</span>
                          is {{ table.size }} ({{ table.size_no_index }} without
                          index)
                        </li>
                      </ul>
                    </li>
                  </ul>
                </div>
              </template>
            </UiListItemExpand>
          </li>
          <li class="mb-4 overflow-hidden rounded-md border border-neutral-300">
            <UiListItemExpand>
              <div class="flex">
                <div
                  class="mr-3 h-6 w-6"
                  :class="
                    muxHealthInfo.warning
                      ? {
                          'i-heroicons-exclamation-triangle-20-solid text-warning': true,
                        }
                      : {
                          'i-heroicons-check-circle-20-solid text-success':
                            muxHealthInfo.available,
                          'i-heroicons-exclamation-circle-20-solid text-error':
                            !muxHealthInfo.available,
                        }
                  "
                />
                Hiro
              </div>

              <template #content>
                <ul class="px-5">
                  <li
                    v-for="{
                      base_url,
                      dns,
                      id,
                      name,
                      result,
                      connectors,
                    } in muxHealthInfo.results"
                    class="py-2"
                  >
                    <div class="flex items-center space-x-2 py-2">
                      <div
                        class="h-6 w-6"
                        :class="{
                          'i-heroicons-check-circle-20-solid text-success': dns,
                          'i-heroicons-exclamation-circle-20-solid text-error':
                            !dns,
                        }"
                      />
                      <div class="h2 text-neutral-800">{{ name }}</div>
                      <div class="subtitle-2 py-1 text-neutral-900">
                        - {{ id }}
                      </div>
                    </div>
                    <div class="px-4">
                      <div v-if="base_url" class="text-sm">
                        {{ base_url }} - {{ dns ?? '?' }}
                      </div>
                      <div class="mt-3 italic">
                        <div>{{ result }}</div>
                      </div>
                    </div>
                    <div v-if="dns && connectors.length">
                      <div class="h4 mt-3 px-5">Connectors:</div>
                      <ul class="px-7">
                        <li
                          v-for="{
                            id,
                            name,
                            healthx,
                            insight_counts,
                          } in connectors"
                          class="py-2"
                        >
                          <div class="flex items-center space-x-2 py-2">
                            <div
                              class="h-6 w-6"
                              :class="{
                                'i-heroicons-check-circle-20-solid text-success':
                                  healthx.status.indexOf('Passed') >= 0,
                                'i-heroicons-exclamation-circle-20-solid text-error':
                                  healthx.status.indexOf('Passed') < 0,
                              }"
                            />
                            <div class="h4 text-neutral-800">
                              {{ id }} - {{ name }}
                            </div>
                          </div>
                          <div class="px-2 italic">
                            {{ healthx.status }} -
                            {{
                              healthx.result?.hiro_metadata
                                ?.response_time_milliseconds
                            }}ms
                          </div>
                        </li>
                      </ul>
                    </div>
                  </li>
                </ul>
              </template>
            </UiListItemExpand>
          </li>
          <li class="overflow-hidden rounded-md border border-neutral-300">
            <UiListItemExpand>
              <div class="flex">
                <div
                  class="mr-3 h-6 w-6"
                  :class="
                    dataIntegrityInfo.passed
                      ? {
                          'i-heroicons-check-circle-20-solid text-success': true,
                        }
                      : {
                          'i-heroicons-exclamation-triangle-20-solid text-error': true,
                        }
                  "
                />
                {{ dataIntegrityInfo.label }}
              </div>

              <template #content>
                <ul class="px-5">
                  <li
                    v-for="{ description, items } in dataIntegrityInfo.checks"
                    class="py-2"
                  >
                    <div class="flex items-center space-x-2 py-2">
                      <div
                        class="h-6 w-6"
                        :class="{
                          'i-heroicons-check-circle-20-solid text-success':
                            !items.length,
                          'i-heroicons-exclamation-circle-20-solid text-error':
                            items.length,
                        }"
                      />
                      <div class="h2 text-neutral-800">{{ description }}</div>
                    </div>
                    <div class="subtitle-2 py-1 text-neutral-900">
                      <ul class="px-5">
                        <li v-for="{ id, name } in items" class="py-2">
                          {{ id }} {{ name }}
                        </li>
                      </ul>
                    </div>
                  </li>
                </ul>
                <ul class="px-5">
                  <li
                    v-for="{
                      policy,
                      description,
                    } in dataIntegrityInfo.policyErrors"
                    class="py-2"
                  >
                    <div class="flex items-center space-x-2 py-2">
                      <div
                        class="i-heroicons-exclamation-circle-20-solid text-error h-6 w-6"
                      />
                      <div class="h2 text-neutral-800">
                        {{ `${description}` }}
                      </div>
                    </div>
                  </li>
                </ul>
              </template>
            </UiListItemExpand>
          </li>
        </ul>
      </section>
      <section class="mb-4">
        <div
          class="inline-flex w-full flex-col items-start justify-center rounded-lg bg-neutral-50"
        >
          <div class="details-grid grid w-full gap-4 p-5 md:gap-x-8">
            <template v-for="{ label, value } in userInfo">
              <div class="subtitle-2 text-neutral-900">{{ label }}</div>
              <div class="body-2 flex flex-col space-y-2 text-neutral-900">
                {{ value }}
              </div>
            </template>
          </div>
        </div>
      </section>
      <section class="mb-4">
        <div
          class="inline-flex w-full flex-col items-start justify-center rounded-lg bg-neutral-50"
        >
          <div class="details-grid grid w-full gap-4 p-5 md:gap-x-8">
            <template v-for="{ label, value } in apiInfo">
              <div class="subtitle-2 text-neutral-900">{{ label }}</div>
              <div class="body-2 flex flex-col space-y-2 text-neutral-900">
                {{ value }}
              </div>
            </template>
          </div>
        </div>
      </section>
    </div>
  </AppDetailsDrawer>
</template>

<style scoped>
.details-grid {
  grid-template-columns: max-content 1fr;
}
</style>
